<template>
  <div class="container" ref="footer">
    <div class="footer-link">
      <router-link to="/privacy"><strong>개인정보처리방침</strong></router-link>
      <router-link to="/provision">이용약관</router-link>
    </div>
    <div class="footer-content">
      <div class="footer-logo">
        <a href="#"><span class="sound-only">미륵사</span></a>
      </div>
      <div class="footer-info">
        <p>
          (주)퓨처월드포 | 충북 청주시 청원구 오창읍 각리1길 7, 오창벤처프라자 404호 | 대표자명 : 이동덕 | 사업자번호 :
          851-81-01819 | 법인등록번호 : 15011-0285432<br />
          Tel 043-903-8952 | Fax 043-904-8952 | E-mail main@futureworld4.com<br />
          Copyright(c) futureworld4. All Right Reserved.
        </p>
        <p class="groups">
          <span>그룹사 : (주)퓨처월드포, U-TECH, U-TECH China, (주)세파</span>
          <span>협력업체 : (주)가람문화재, (주)프롬더레드, (사)한국창업멘토협회, 특허법인 도담</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layout_footer',
  mounted() {
    // TODO: bottom position
    // console.log(document.body.clientHeight)
    // let footer = this.$refs.footer
    // console.log(footer.getBoundingClientRect().y)
    // console.log(footer.clientHeight)
    const url = window.location.origin
    console.log('origin', url)
  }
}
</script>

<template>
  <div class="pop-wrap" style="width:400px">
    <!-- <div class="pop-header">
      <h3></h3>
    </div> -->
    <button type="button" class="btn-close" @click="$emit('close')">닫기</button>
    <div class="pop-content">
      <b-form @submit.prevent="chgNick()">
        <h3 class="text-center">{{ nick }}</h3>

        <div class="form-group">
          <label for="member_id" class="label">닉네임<span class="label-req">*</span></label>
          <div class="input-container">
            <input
              type="text"
              v-model="new_nick"
              class="dx-input full"
              placeholder="변경할 닉네임을 입력하세요! (4~20자)"
              @change="chk_nick = false"
            />
            <button type="button" class="button" @click.prevent="chkNick()" v-if="!chk_nick">중복체크</button>
            <button type="button" class="button" v-else><i class="fa fa-check"></i></button>
          </div>
        </div>
        <div class="write-button">
          <!-- <button type="button" class="btn dx-input" @click="$emit('close')">변경안하기</button> -->
          <button type="submit" class="btn-submit  full" :disabled="!chk_nick">닉네임 변경하기</button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import * as Api from '../api'
export default {
  props: ['nick'],
  data() {
    return {
      new_nick: '',
      chk_nick: false,
      joinPop: false
    }
  },
  methods: {
    async chkNick() {
      await Api.auth
        .chkNick({ nick: this.new_nick })
        .then(res => {
          console.log(res)
          this.$notify({ text: res.message })
          this.chk_nick = true
          return true
        })
        .catch(err => {
          console.log(err)
          this.chk_nick = false
          this.$notify({ text: err.error, type: 'error', group: 'error' })
          return true
        })
    },
    async chgNick() {
      if (this.chk_nick) {
        await Api.auth
          .update_nick({ nick: this.new_nick })
          .then(res => {
            console.log(res)
            this.$notify({ text: res.message })
            this.$store.dispatch('infoUser')
            // this.$bvModal.hide('bv-modal-nick')
            this.$emit('close')
          })
          .catch(err => {
            console.log(err)
            this.$notify({ text: err.error, type: 'error', group: 'error' })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-content {
  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .input-group {
    input,
    .input-group-append {
      display: inline-block;
    }
    button,
    span {
      background: rgb(148, 255, 189);
      height: 40px;
      padding: 3px 10px;
      border: 1px solid #cbcbcb;
      border-left: 0;
    }
  }
}
</style>

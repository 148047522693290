<template>
  <div>
    <!-- <div id="skip-contents"><a href="#contents">본문 바로가기</a></div> -->
    <div class="container">
      <div class="logo">
        <router-link to="/"><span class="sound-only">미륵사</span></router-link>
      </div>

      <nav id="gnb">
        <button type="button" class="menu-button" onclick="mobile_menu_open()"><span></span></button>
        <ul class="topmenu">
          <li><router-link to="/" class="home">HOME</router-link></li>
          <li><router-link to="/about">사찰소개</router-link></li>
          <li><a href="https://www.fw4live.com/" target="_blank">실시간 방송보기</a></li>
          <li>
            <a href="https://cafe.naver.com/maitreyatemple/" target="_blank">
              <img src="/images/icons/cafe_ico.png" width="32" style="margin-top:-10px;" />
              미륵사 공식카페
            </a>
          </li>
          <!-- <li><router-link to="/board">게시판</router-link></li> -->
        </ul>
      </nav>
      <div class="tnb">
        <ul>
          <!-- <li><router-link to="/notice">공지사항</router-link></li>
          <li><router-link to="/faq">FAQ</router-link></li> -->
          <!-- <li><router-link to="/board">게시판</router-link></li> -->
          <!-- <li><router-link to="/qna" v-if="isLogin">고객센터</router-link></li> -->
          <li v-if="!isLogin"><router-link to="/join" class="join">회원가입</router-link></li>
          <li v-if="isLogin">
            <img :src="User.picture" class="avata" />
            <span class="nickname">{{ User.nick }}</span>
            <button type="button" class="logout" @click.prevent="signOut()">
              로그아웃
            </button>
          </li>
          <li v-else><router-link to="/login" class="login">로그인</router-link></li>

          <!-- <li class="start-btn">
            <router-link to="/login" v-if="!isLogin">미륵사 시작</router-link>
            <span v-else @click="btnPlay()" style="cursor: pointer">미륵사 시작</span>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- // #header -->
    <div class="mobile-menu right-side" id="mobile-category">
      <a href="javascript:;" onclick="mobile_menu_close()" class="close-menu"></a>
      <div class="menu-bg"></div>
      <ul class="menu-slide">
        <div class="mobile-header" v-if="isLogin">
          <img :src="User.picture" class="avata" />
          <span class="nickname">{{ User.nick }}</span>
          <!-- <button type="button" class="btn btn-dark" @click.prevent="signOut()" title="로그아웃">
            <i class="fa fa-sign-out" style="color:white;font-size:18px;"></i>
          </button> -->
          <button type="button" class="logout" @click.prevent="signOut()">
            로그아웃
          </button>
        </div>
        <div class="mobile-header" v-else>
          <router-link to="/login" class="login" onclick="mobile_menu_close()">로그인</router-link>
          <router-link to="/join" onclick="mobile_menu_close()">회원가입</router-link>
        </div>
        <li><router-link to="/" onclick="mobile_menu_close()">홈</router-link></li>
        <li><router-link to="/about" onclick="mobile_menu_close()">사찰소개</router-link></li>
        <li><a href="https://www.fw4live.com/" target="_blank">실시간 방송보기</a></li>
        <li><a href="https://cafe.naver.com/maitreyatemple/" target="_blank">미륵사 공식카페</a></li>
        <!-- <li><router-link to="/board" onclick="mobile_menu_close()">게시판</router-link></li> -->
        <!-- <li><router-link to="/board/3">게시판</router-link></li>
        <li><router-link to="/qna">고객센터</router-link></li> -->
      </ul>
    </div>

    <!-- <div class="row align-items-center">
      <div class="col-auto">
        <router-link to="/"><h5>LOGO</h5></router-link>
      </div>
      <div class="col-auto mr-auto">
        <ul class="nav">
          <router-link to="/about" class="nav-link menu-link">About</router-link>
          <router-link to="/board" class="nav-link menu-link">Notice</router-link>
          <router-link to="/board/2" class="nav-link menu-link">FAQ</router-link>
          <router-link to="/board/3" class="nav-link menu-link">Board</router-link>
          <template v-if="isLogin">
            <router-link to="/iam" class="nav-link menu-link">IAM</router-link>
            <router-link to="/qna" class="nav-link menu-link" v-if="$PUB.pub == 'MR'">1:1고객센터</router-link>
          </template>
        </ul>
      </div>
      <div class="col-auto">
        <template v-if="isLogin">
          <button type="button" class="btn" @click.prevent="$router.push({ name: 'Login' }).catch(() => {})">
            <img :src="User.picture" width="24" />
            <span>{{ User.nick }}</span>
          </button>
          <button type="button" class="btn btn-dark" @click.prevent="signOut()">로그아웃</button>
        </template>
        <template v-else>
          <router-link to="/login">
            <button type="button" class="btn btn-dark">로그인</button>
          </router-link>
          <router-link to="/join">
            <button type="button" class="btn btn-light">회원가입</button>
          </router-link>
        </template>
      </div>
    </div> -->

    <div class="layer-popup" v-if="User && nickPop">
      <div class="layer-dim"></div>
      <popup-nick :nick="User.nick" v-if="User" @close="nickPop = !nickPop" />
    </div>
  </div>
</template>

<script>
import * as Api from '../api'
import { mapGetters } from 'vuex'

import PopupNick from '../components/_nickPop.vue'

export default {
  name: 'layout_header',
  components: {
    PopupNick,
  },

  data() {
    return {
      nick: '',
      idToken: '',
      user: {},
      chk_nick: false,
      joinPop: false,
      timeout: null,
      nickPop: false,
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'User', 'wsOn', 'ws_id']),
  },
  async created() {
    this.$firebase.auth().onAuthStateChanged((user) => {
      console.log('firebase auth', user)
      if (user) {
        user.getIdToken().then((idToken) => {
          this.idToken = idToken
          this.fLogin()
        })
      } else {
        console.log('No login')
      }
    })
  },
  watch: {
    isLogin: {
      immediate: true,
      handler(val) {
        console.log('! isLogin', val)
        if (val) {
          if (!this.timeout) {
            this.ping()
            this.timeout = setInterval(() => {
              this.ping()
            }, 1000 * 60 * 10)
          }
        } else {
          this.$PUB && this.$PUB.use.websocket && this.onSocket()
          this.timeout && clearInterval(this.timeout)
          this.pubtoken = localStorage.getItem('pubtoken')
          this.pubtoken && this.$store.dispatch('infoUser')
        }
      },
    },
  },
  methods: {
    signOut() {
      console.log('-> signOut')

      var r = confirm('로그아웃 합니다.')
      if (r) {
        this.$store.dispatch('setLogout')
        clearInterval(this.timeout)
      }

      // this.$bvModal
      //   .msgBoxConfirm('로그아웃 합니다.', {
      //     title: '경고',
      //     size: 'sm',
      //     buttonSize: 'sm',
      //     okVariant: 'success',
      //     headerClass: 'p-2 border-bottom-0',
      //     footerClass: 'p-2 border-top-0',
      //     centered: true
      //   })
      //   .then(value => {
      //     if (!value) return
      //     this.$store.dispatch('setLogout')
      //     clearInterval(this.timeout)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    async fLogin() {
      console.log('-> fLogin')
      if (!this.pubtoken) {
        console.log('Api.auth.flogin')
        let payload = {
          idToken: this.idToken,
        }
        if (this.wsOn) payload.ws_id = this.ws_id
        await Api.auth
          .flogin(payload)
          .then((res) => {
            if (this.wsOn) return
            this.on_logon(res)
          })
          .catch((err) => {
            localStorage.removeItem('pubtoken')
            this.$notify({ text: err.error, type: 'error', group: 'error' })
          })
      }
    },
    async ping() {
      console.log('-> ping')
      await Api.user
        .ping()
        .then((res) => {
          console.log('#user ping', res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    chk_login() {
      console.log('-> chk_login')
      const pubtoken = localStorage.getItem('pubtoken')
      if (!pubtoken) return
      this.$store.dispatch('infoUser')
    },

    on_logon(res) {
      console.log('-> on_logon')
      localStorage.setItem('pubtoken', res.pubtoken)
      this.$store.dispatch('infoUser')
      this.$notify({ text: '로그인 되었습니다.' })

      // 닉네임변경
      if (res.can && res.can.chgNick) {
        this.nickPop = true
      }

      //본인인증 무시.
      // if (res.need && res.need == 'adultCert') {
      //   console.log('chk')
      //   this.winKcert = window.open(res.certurl, '카카오 본인인증', 'width=400, height=600')
      // }
    },
    //////////////////////////////////
    onSocket() {
      console.log('-> onSocket')
      if (!this.$PUB.wsUrl) return
      if (this.ws) this.ws.close()
      const ws = new WebSocket(this.$PUB.wsUrl + '/sess')
      this.ws = ws
      ws.onopen = () => {
        ws.onmessage = (msg) => {
          const { act, data, error } = JSON.parse(msg.data)
          if (error) return this.$notify({ text: error, type: 'error', group: 'error' })
          console.log('#WS', act, data)
          switch (act) {
            case 'connected':
              this.$store.commit('SET_WS', data.ws_id)
              break
            case 'logon':
              this.on_logon(data)
              break
            default:
              console.log('#WS', 'wrong act', act)
              break
          }
        }
        ws.onclose = () => {
          console.log('#WS closed', this.ws_id)
          this.$store.commit('DEL_WS')
        }
      }
    },
    btnPlay() {
      console.log('-> btnPlay')
      let pubtoken = localStorage.getItem('pubtoken')
      if (!pubtoken) return alert('로그인하세요.')
      let exportUrl = `maitreyatemple://?token=${pubtoken}`

      window.location.href = exportUrl
    },
  },

  beforeDestroy() {
    console.log('-> beforeDestroy')
    console.log('clearInterval')
    clearInterval(this.timeout)
    if (this.ws) {
      this.ws.close()
      this.$store.commit('DEL_WS')
    }
  },
}
</script>
<style lang="scss">
.start-btn {
  background-color: rgb(255, 187, 0);
  font-weight: bolder;
  font-size: 21px;
  padding: 20px 36px !important;
  margin-top: 10px;
}
.avata {
  border-radius: 50%;
  vertical-align: middle;
  margin: 5px;
  width: 32px;
}
.nickname {
  font-size: 18px;
  font-weight: bold;
}

.logout {
  border: 2px solid #5e5e5e;
  color: #5e5e5e;
  border-radius: 17px;
  width: 90px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 32px;
  margin: 0 10px;
}
.logout:hover {
  background-color: #5e5e5e;
  color: #fff;
}
</style>

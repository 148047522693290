<template>
  <div id="wrapper">
    <header id="header">
      <header-layout />
    </header>

    <div id="contents">
      <router-view />
    </div>
    <footer id="footer">
      <footer-layout />
    </footer>

    <notifications position="top center" />
    <notifications group="error" position="top center" />
    <div class="debug" v-if="showDebug">
      <!-- <pre>{{ $store.state }}</pre> -->
      <json-viewer :value="$store.state"></json-viewer>
      <div class="debug_fun">
        <b-btn @click.prevent="dropout">탈퇴</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import * as Api from '../api'
import headerLayout from './_header'
import footerLayout from './_footer'

export default {
  name: 'layout',
  components: {
    headerLayout,
    footerLayout,
    JsonViewer,
  },
  data() {
    return {
      showDebug: false,
    }
  },
  methods: {
    async dropout() {
      await Api.auth
        .dropout()
        .then((res) => {
          console.log(res)
          this.$notify({ text: '탈퇴 신청 되었습니다.' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    document.title = this.$PUB.title
  },
  mounted() {
    window.addEventListener(
      'keypress',
      function(e) {
        if (e.keyCode == 29) this.showDebug = !this.showDebug
      }.bind(this)
    )
  },
}
</script>
<style lang="scss">
.badge-info {
  background-color: #2b65d9;
}
body {
  background: #13161d;
}
#wrapper {
  background: white;
}
</style>
